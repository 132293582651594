const ID_TOKEN_KEY = "id_token"
const APPOINTMENT_FORM_KEY = "hNVna4uY"

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY)
}

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token)
}

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY)
  window.localStorage.removeItem("access_right")
  window.localStorage.removeItem("user")
}

export const getUser = () => {
  return JSON.parse(window.localStorage.getItem('user'))
}

export const setUser = (user) => {
  window.localStorage.setItem('user', user)
}

export const setNewInvoice = (new_invoice) => {
  window.localStorage.setItem('new_invoice', new_invoice)
}

export const setConfiguration = (configuration) => {
  window.localStorage.setItem('configuration', configuration)
}

export const getConfiguration = () => {
  return JSON.parse(window.localStorage.getItem('configuration'))
}

export const setAppointmentForm = (form) => {
  window.localStorage.setItem(APPOINTMENT_FORM_KEY, JSON.stringify(form))
}

export const getAppointmentForm = () => {
  return JSON.parse(window.localStorage.getItem(APPOINTMENT_FORM_KEY))  
}

export const removeAppointmentForm = () => {
  window.localStorage.removeItem(APPOINTMENT_FORM_KEY)
}

export default { getToken, saveToken, destroyToken, getUser, setUser, setNewInvoice, setConfiguration, getConfiguration, setAppointmentForm, getAppointmentForm, removeAppointmentForm }
